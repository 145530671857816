// Export Import Main Panel Buttons except for bank
<template>
  <div class="ei-container">
    <div v-if="hasItems" class="link" @click="exportItems()">
      <uds-icon class="link-icon" icon-name="download" size="small" />
      <span>Exporter</span>
    </div>

    <div v-if="!noImport" class="link" @click="$emit('openNewItem')">
      <uds-icon class="link-icon" icon-name="upload" size="small" />
      <span>Importer</span>
    </div>
  </div>
</template>

<script setup lang="ts">
defineEmits(["openNewItem"]);

const props = withDefaults(
  defineProps<{
    hasItems: boolean;
    exportKey: string;
    state: string;
    noImport?: boolean;
  }>(),
  {
    noImport: false,
  },
);

const storeComposable = useStoreComposable();
const dayjs = useDayjs();
const societyStore = useSocietyStore();

const activeStore = computed(() => storeComposable.activeStore(props.state));
const idSociety = computed(() => societyStore.society!.id!);

// @ts-expect-error
const startDate = computed(() => activeStore.value?.queryProperties?.startDate ?? undefined);
// @ts-expect-error
const endDate = computed(() => activeStore.value?.queryProperties?.endDate ?? undefined);

async function exportItems() {
  const buffer = await $silex().society.export(idSociety.value, {
    keys: [props.exportKey],
    startDate: startDate.value,
    endDate: endDate.value,
  });
  downloadBuffer(buffer, `${props.exportKey}_${dayjs().format("YYYYMMDD_hhmmss")}`);
}
</script>

<style lang="scss" scoped>
.ei-container {
  margin-top: $uds-spacing-2;
  display: flex;
  align-items: center;
  gap: $uds-spacing-1_5;
  font-size: 14px;
  color: $uds-neutral-600;

  .link {
    display: flex;
    align-items: center;
    gap: $uds-spacing-0_5;

    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }
    .link-icon,
    .link-icon path {
      color: $uds-neutral-600;
      width: 15px;
      height: 15px;
    }
    &:hover,
    &:hover > .link-icon {
      color: $uds-primary-500;
    }
    &:hover > .link-icon path {
      fill: $uds-primary-500;
    }
  }
}
</style>
